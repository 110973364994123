import React from 'react';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {BasicModal, IMultiselectOption, authTokenSelector} from 'educat-common-web';
import styles from "./styles.module.scss";
import ProfileForm from "../Profile/ProfileForm";
import {getSubjectsAPI} from "../../api/getSubjects";
import {catchError, map} from "rxjs/operators";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";


interface IConnectedApplicantSurveyProps {
    authToken: string;
}

interface IExternalApplicantSurveyProps {
    closeModal?: () => void;
}

interface IApplicantSurveyProps extends IConnectedApplicantSurveyProps,
    IExternalApplicantSurveyProps {
}

interface IApplicantSurveyState {
    subjects: typeof IMultiselectOption[] | [];
}


class ApplicantSurvey extends React.Component<IApplicantSurveyProps, IApplicantSurveyState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IApplicantSurveyProps) {
        super(props);

        this.state = {
            subjects: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.subscriptions.push(this.getSubjectsList().subscribe());
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <BasicModal isModalShown={true}>
                <div className={`${styles.modalWrapper} applicant-survey`}>
                    <ProfileForm subjects={this.state.subjects}
                                 isProfileFormShortened={true}/>

                </div>
            </BasicModal>
        );
    }

    private getSubjectsList = () => {
        return getSubjectsAPI(this.props.authToken).pipe(
            map((resp: any) => {
                let list: typeof IMultiselectOption[] = [];
                resp['hydra:member'].map((subject: {[key: string]: any}) => {
                    return list.push({
                        value: subject.id,
                        label: subject.name
                    })
                });
                return this.setState({subjects: list});
            }),
            catchError((error: any) => {
                this.alertManager?.handleApiError(error);
                return of();
            })
        );
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(ApplicantSurvey);
